<template>
  <div class="user-group-manager">
    <en-table-layout :tableData="tableData.data" @selection-change="handleSelectionChange">
      <template slot="toolbar">
        <div class="col toolbar-title">
          用户分组管理
        </div>
        <div class="col-auto">
          <el-button type="primary" @click="addGroup" size="small">添加分组</el-button>
        </div>
      </template>

      <template slot="table-columns">
        <el-table-column label="编号" width="80">
          <template slot-scope="scope">
            {{ scope.$index + 1 + (tableData.page_no - 1) * tableData.page_size }}
          </template>
        </el-table-column>

        <el-table-column prop="group_name" label="分组" min-width="200" show-overflow-tooltip />

        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="handleEdit(scope.$index, scope.row)">
              编辑
            </el-button>

            <el-button size="mini" type="danger" plain @click="handleDelete(scope.$index, scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </template>

      <el-pagination slot="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="inform.page_no" :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
        :page-size="inform.page_size" :total="total"></el-pagination>
    </en-table-layout>

    <x-dialog :proxy="groupDialog">
      <el-form :model="dynamicValidateForm" ref="dynamicValidateForm" class="demo-dynamic" label-width="70px">
        <el-form-item label="分组名称">
          <el-input v-model="dynamicValidateForm.group_name"></el-input>
        </el-form-item>
      </el-form>
    </x-dialog>
  </div>
</template>

<script>
import updateFz from './update';
import * as API_Users from '@/api/users';
import EnTableLayout from '../../../ui-components/TableLayout/src/main';
import XDialog from '@/components/x-dialog/x-dialog';
import { $xDialog } from '@/components/x-dialog/dialog.proxy';

export default {
  components: {
    XDialog,
    EnTableLayout,
    updateFz
  },
  data () {
    return {
      groupDialog: $xDialog.create({
        width: '400px',
        beforeConfirm: () => this.submitForm(),
      }),
      total: 0,
      flag: false,
      parentmsg: '',
      currentPage4: 4,
      formInline: {
        user: '',
        region: ''
      },
      inform: {
        page_size: 20,
        page_no: 1
      },
      page: {},
      tableData: {},
      multipleSelection: [],
      dynamicValidateForm: {
        group_name: '',
        id: ''
      }
    };
  },
  mounted () {
    this.getList();
  },
  methods: {
    submitForm () {
      if (this.dynamicValidateForm.group_name == "暂无") {
        this.$message.error('当前名称为系统默认使用名称，不可作为分组名称')
        return false
      }
      return new Promise(resolve => {
        if (this.dynamicValidateForm.id) {
          API_Users.updateUserfz(
            this.dynamicValidateForm.id,
            this.dynamicValidateForm
          ).then(res => {
            this.$message.success('分组修改成功');
            this.getList().then(resp => resolve(resp));
          });
        } else {
          API_Users.addUserfz(this.dynamicValidateForm).then(res => {
            this.$message.success('分组添加成功');
            this.getList().then(resp => resolve(resp));
          });
        }
      });
    },
    addGroup () {
      this.dynamicValidateForm = {};
      this.groupDialog.display({
        title: '添加分组'
      });
    },
    getList () {
      let params = this.inform;
      return API_Users.getUserlist(params).then(res => {
        this.total = res.data_total;
        this.tableData = res;
        return true;
      }).catch(err => false);
    },
    handleSelectionChange (val) {
      this.multipleSelection = val;
    },
    handleEdit (index, row) {
      this.dynamicValidateForm.group_name = row.group_name;
      this.dynamicValidateForm.id = row.group_id;
      this.groupDialog.display({
        title: '编辑分组',
      });
    },
    handleDelete (index, row) {
      this.$confirm('确认删除分组吗?', '提示', { type: 'warning' }).then(() => {
        API_Users.deleteUserfz(row.group_id).then(res => {
          this.$message.success('分组删除成功');
          this.getList();
        });
      });
    },
    handleSizeChange (val) {
      this.inform.page_size = val;
      this.getList();
    },
    handleCurrentChange (val) {
      this.currentPage4 = val;
      this.inform.page_no = val;
      this.getList();
    }
  }
};
</script>

<style lang="scss"></style>
