<template>
  <div class="bjfz">
    <!-- <h3>编辑分组</h3> -->
    <el-form
      :model="dynamicValidateForm"
      ref="dynamicValidateForm"
      label-width="100px"
      class="demo-dynamic"
    >
      <el-form-item :label="'分组名称'">
        <el-input v-model="dynamicValidateForm.group_name"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="submitForm(dynamicValidateForm)">提交</el-button>
        <el-button type="primary" size="small" @click="close">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import * as API_Users from "@/api/users";
export default {
  props: {
    famsg: Object
  },
  data() {
    return {
      flag: false,
      dynamicValidateForm: {
        group_name: this.famsg.group_name,
        id: this.famsg.group_id
      }
    };
  },
  methods: {
    submitForm(formName) {
      console.log(this.famsg, "dasd");
      if (this.famsg.group_id) {
        API_Users.updateUserfz(
          this.dynamicValidateForm.id,
          this.dynamicValidateForm
        ).then(res => {
          console.log("修改成功");
          this.$emit("fromfzson", this.flag);
          location.reload();
        });
      } else {
        console.log(this.dynamicValidateForm.group_name,"name")
        API_Users.addUserfz(this.dynamicValidateForm).then(res => {
          this.$emit("fromfzson", this.flag);
          location.reload();
        });
      }
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    removeDomain(item) {
      var index = this.dynamicValidateForm.domains.indexOf(item);
      if (index !== -1) {
        this.dynamicValidateForm.domains.splice(index, 1);
      }
    },
    addDomain() {
      this.dynamicValidateForm.domains.push({
        value: "",
        key: Date.now()
      });
    },
    close() {
      this.$emit("fromfzson", this.flag);
    }
  }
};
</script>
<style lang="scss" scoped>
.bjfz {
  text-align: center;
  padding-top: 20px;
  padding-right: 20px;
  width: 400px;
}
</style>
